import { Box, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        width: "100%",
        textAlign: "center",
        p: 2,
        mt: "auto",
        backgroundColor: "background.default",
        borderTop: "1px solid",
        borderColor: "divider",
      }}
    >
      <Typography variant="body2" color="textSecondary">
        © {new Date().getFullYear()} Homefin. Todos os direitos reservados.
      </Typography>
    </Box>
  );
};

export default Footer;
