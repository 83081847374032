import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  parceiros: {
    loading: false,
    error: false,
    data: []
  },
  parceiro: {
    loading: false,
    error: false,
    data: null,
    isEditMode: false
  },
  buscarCep: {
    loading: false,
    error: null
  },
  usuariosParceiros: {
    loading: false,
    error: null,
    data: []
  },
  arquivos: {
    loading: false,
    error: false,
    data: []
  },
  arquivo: {
    loading: false,
    error: false,
    data: null,
    referencia: null
  },
  fup: {
    loading: false,
    error: false
  },
  fups: {
    loading: false,
    error: false,
    data: []
  },
  identity: {
    loading: false,
    error: false,
    data: [],
    registros: [],
  }
};

const ParceirosSlice = createSlice({
  name: 'parceiros',
  initialState,
  reducers: {
    listarParceirosRequest: (state) => {
      state.parceiros.loading = true;
      state.parceiros.error = null;
      state.parceiros.data = [];
    },
    listarParceirosSuccess: (state, action) => {
      state.parceiros.loading = false;
      state.parceiros.error = false;
      state.parceiros.data = action.payload.response.data;
    },
    listarParceirosFailure: (state, action) => {
      state.parceiros.loading = false;
      state.parceiros.error = action.payload;
    },
    criarParceiroRequest: (state, action) => {
      state.parceiro.loading = true;
      state.parceiro.loading = true;
      state.parceiro.error = null;
    },
    criarParceiroSuccess: (state, action) => {
      state.parceiro.loading = false;
      state.parceiro.loading = false;
      state.parceiro.error = false;
    },
    criarParceiroFailure: (state, action) => {
      state.parceiro.loading = false;
      state.parceiro.loading = false;
      state.parceiro.error = action.payload;
    },
    editarParceiroRequest: (state, action) => {
      state.parceiro.loading = true;
      state.parceiro.loading = true;
      state.parceiro.error = null;
    },
    editarParceiroSuccess: (state, action) => {
      state.parceiro.loading = false;
      state.parceiro.loading = false;
      state.parceiro.error = false;
    },
    editarParceiroFailure: (state, action) => {
      state.parceiro.loading = false;
      state.parceiro.loading = false;
      state.parceiro.error = action.payload;
    },
    mostrarParceiroRequest: (state, action) => {
      state.parceiro.loading = true;
      state.parceiro.data = null;
      state.parceiro.error = null;
    },
    mostrarParceiroSuccess: (state, action) => {
      state.parceiro.loading = false;
      state.parceiro.data = action.payload.response.data;
      state.parceiro.error = false;
    },
    mostrarParceiroFailure: (state, action) => {
      state.parceiro.loading = false;
      state.parceiro.loading = false;
      state.parceiro.error = action.payload;
    },
    desativarModoEdicao: (state) => {
      state.parceiro.isEditMode = false;
    },
    ativarModoEdicao: (state) => {
      state.parceiro.isEditMode = true;
    },
    limparParceiro: (state) => {
      state.parceiro.data = null;
    },
    excluirParceiroRequest: (state) => {
      state.parceiros.loading = true;
      state.parceiros.error = null;
    },
    excluirParceiroSuccess: (state) => {
      state.parceiros.loading = false;
      state.parceiros.error = false;
    },
    excluirParceiroFailure: (state, action) => {
      state.parceiros.loading = false;
      state.parceiros.error = action.payload;
    },
    buscarCepRequest: (state) => {
      state.buscarCep.loading = true;
      state.buscarCep.error = null;
    },
    buscarCepSuccess: (state) => {
      state.buscarCep.loading = false;
      state.buscarCep.error = false;
    },
    buscarCepFailure: (state, action) => {
      state.buscarCep.loading = false;
      state.buscarCep.error = action.payload;
    },
    listarUsuariosParceirosRequest: (state) => {
      state.usuariosParceiros.loading = true;
      state.usuariosParceiros.error = null;
      state.usuariosParceiros.data = [];
    },
    listarUsuariosParceirosSuccess: (state, action) => {
      state.usuariosParceiros.loading = false;
      state.usuariosParceiros.error = false;
      state.usuariosParceiros.data = action.payload.response.data;
    },
    listarUsuariosParceirosFailure: (state, action) => {
      state.usuariosParceiros.loading = false;
      state.usuariosParceiros.error = action.payload;
    },
    listarArquivosRequest: (state, action) => {
      state.arquivos.loading = true;
      state.arquivos.error = null;
      state.arquivos.data = [];
    },
    listarArquivosSuccess: (state, action) => {
      state.arquivos.loading = false;
      state.arquivos.error = false;
      state.arquivos.data = action.payload.response.data;
    },
    listarArquivosFailure: (state, action) => {
      state.arquivos.loading = false;
      state.arquivos.error = action.payload;
    },
    uploadArquivoRequest: (state, action) => {
      state.arquivo.loading = true;
      state.arquivo.error = null;
    },
    uploadArquivoSuccess: (state, action) => {
      state.arquivo.loading = false;
      state.arquivo.data = action.payload.response.data;
    },
    uploadArquivoFailure: (state, action) => {
      state.arquivo.loading = false;
      state.arquivo.error = action.payload;
    },
    deletarArquivoRequest: (state, action) => {
      state.arquivo.loading = true;
      state.arquivo.error = null;
    },
    deletarArquivoSuccess: (state, action) => {
      state.arquivo.loading = false;
    },
    deletarArquivoFailure: (state, action) => {
      state.arquivo.loading = false;
      state.arquivo.error = action.payload;
    },
    criarFupRequest: (state) => {
      state.fups.loading = true;
      state.fups.error = null;
    },
    criarFupSuccess: (state) => {
      state.fups.loading = false;
      state.fups.error = false;
    },
    criarFupFailure: (state, action) => {
      state.fups.loading = false;
      state.fups.error = action.payload;
    },
    listarFupsRequest: (state) => {
      state.fups.loading = true;
      state.fups.error = null;
      state.fups.data = [];
    },
    listarFupsSuccess: (state, action) => {
      state.fups.loading = false;
      state.fups.error = false;
      state.fups.data = action.payload.response.data;
    },
    listarFupsFailure: (state, action) => {
      state.fups.loading = false;
      state.fups.error = action.payload;
    },
    reverterFups: (state, action) => {
      state.fups.data = state.fups.data.reverse();
    },
    criarIdentityRequest: (state, action) => {
      state.identity.loading = true;
    },
    criarIdentitySuccess: (state, action) => {
      state.identity.loading = false;
      state.identity.data = action.payload.response.data;
      state.identity.error = ""
    },
    criarIdentityFailure: (state, action) => {
      state.identity.loading = false;
      state.identity.error = action.payload;
    },
    consultarIdentityRequest: (state, action) => {
      state.identity.loading = true;
    },
    consultarIdentitySuccess: (state, action) => {
      state.identity.loading = false;
      state.identity.registros = action.payload.response.data.registrosDNS;
      state.identity.status = action.payload.response.data.statusVerificacao;
      state.identity.error = action.payload.response.data.erro;
    },
    consultarIdentityFailure: (state, action) => {
      state.identity.loading = false;
      state.identity.error = action.payload;
    }
  }
});

export const {
  listarParceirosRequest,
  listarParceirosSuccess,
  listarParceirosFailure,
  adicionarOportunidade,
  criarParceiroRequest,
  criarParceiroSuccess,
  criarParceiroFailure,
  mostrarParceiroRequest,
  mostrarParceiroSuccess,
  mostrarParceiroFailure,
  editarParceiroRequest,
  editarParceiroSuccess,
  editarParceiroFailure,
  adicionarEtapa,
  reverterParceiros,
  desativarModoEdicao,
  ativarModoEdicao,
  limparParceiro,
  excluirParceiroRequest,
  excluirParceiroSuccess,
  excluirParceiroFailure,
  buscarCepRequest,
  buscarCepSuccess,
  buscarCepFailure,
  listarUsuariosParceirosRequest,
  listarUsuariosParceirosSuccess,
  listarUsuariosParceirosFailure,
  listarArquivosRequest,
  listarArquivosSuccess,
  listarArquivosFailure,
  uploadArquivoRequest,
  uploadArquivoSuccess,
  uploadArquivoFailure,
  deletarArquivoRequest,
  deletarArquivoSuccess,
  deletarArquivoFailure,
  criarFupRequest,
  criarFupSuccess,
  criarFupFailure,
  listarFupsRequest,
  listarFupsSuccess,
  listarFupsFailure,
  reverterFups,
  criarIdentityRequest,
  criarIdentitySuccess,
  criarIdentityFailure,
  consultarIdentityFailure,
  consultarIdentityRequest,
  consultarIdentitySuccess,
} = ParceirosSlice.actions;

export default ParceirosSlice.reducer;
