import React from 'react';
import { Box, Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';
import OutlinedInput from '../../../../components/form/OutlinedInput';
import CpfCnpjTextField from '../../../../components/form/CpfCnpjTextField';
import DateTextField from '../../../../components/form/DateTextField';
import { useSelector } from 'react-redux';
import ControlledAutocomplete from 'components/form/Autocomplete';
import CurrencyTextField from '../../../../components/form/CurrencyTextField';

const Conjuge = ({ formProps, estadoCivil }) => {
  const ufs = useSelector((state) => state.dominios.unidadesFederativa);
  const tiposDocumentos = useSelector(
    (state) => state.dominios.tiposDocumentos
  );
  const tiposEstadoCivil = useSelector(
    (state) => state.dominios.tiposEstadoCivil
  );

  const tiposRegimeCasamento = useSelector(
    (state) => state.dominios.tiposRegimeCasamento
  );

  if (estadoCivil !== 'CA') {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Box sx={{ flex: 1 }}>
        <InputLabel htmlFor="tipoRegimeCasamento">
          Regime de Casamento
        </InputLabel>
        <Stack spacing={0}>
          <ControlledAutocomplete
            id="tipoRegimeCasamento"
            name="tipoRegimeCasamento"
            placeholder="Selecione o Regime de Casamento"
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'id'}
            domain={tiposRegimeCasamento}
          />
        </Stack>
      </Box>

      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="nomeConjuge">Nome</InputLabel>
          <OutlinedInput
            id="nomeConjuge"
            name="nomeConjuge"
            placeholder="Digite o nome do cônjuge"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="cpfConjuge">CPF</InputLabel>
          <OutlinedInput
            id="cpfConjuge"
            name="cpfConjuge"
            placeholder="Digite o CPF"
            fullWidth
            type="cpf"
            formProps={formProps}
            InputProps={{
              inputComponent: CpfCnpjTextField,
              maxLength: 15
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="dataNascimentoConjuge">
            Data de Nascimento
          </InputLabel>
          <OutlinedInput
            id="dataNascimentoConjuge"
            name="dataNascimentoConjuge"
            placeholder="Selecione a data"
            fullWidth
            formProps={formProps}
            InputProps={{
              inputComponent: DateTextField,
              maxLength: 14
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <InputLabel htmlFor="tipoEstadoCivilConjuge">Estado Civil</InputLabel>
        <Stack spacing={0}>
          <ControlledAutocomplete
            id="tipoEstadoCivilConjuge"
            name="tipoEstadoCivilConjuge"
            placeholder="Selecione o Estado de Civil"
            required
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'id'}
            domain={tiposEstadoCivil}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <InputLabel htmlFor="tipoDocumentoIdentidadeConjuge">
          Documento de Identidade
        </InputLabel>
        <Stack spacing={0}>
          <ControlledAutocomplete
            id="tipoDocumentoIdentidadeConjuge"
            name="tipoDocumentoIdentidadeConjuge"
            placeholder="Selecione o Tipo de Documento"
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'id'}
            domain={tiposDocumentos}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="numeroDocumentoConjuge">
            Número do Documento
          </InputLabel>
          <OutlinedInput
            id="numeroDocumentoConjuge"
            name="numeroDocumentoConjuge"
            placeholder="Digite o número do documento"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 15 }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="dataExpedicaoConjuge">
            Data de Expedição
          </InputLabel>
          <OutlinedInput
            id="dataExpedicaoConjuge"
            name="dataExpedicaoConjuge"
            placeholder="Selecione a data"
            fullWidth
            formProps={formProps}
            InputProps={{
              inputComponent: DateTextField,
              maxLength: 14
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="orgaoExpedidorConjuge">
            Órgão Expedidor
          </InputLabel>
          <OutlinedInput
            id="orgaoExpedidorConjuge"
            name="orgaoExpedidorConjuge"
            placeholder="Digite o órgão expedidor"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 15 }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor={`ufExpedicaoConjuge`}>
            UF de Expedição
          </InputLabel>
          <ControlledAutocomplete
            id="ufExpedicaoConjuge"
            name="ufExpedicaoConjuge"
            placeholder="Selecione o Estado de Expedição"
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'codigo'}
            domain={ufs}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="nomeProfissaoConjuge">Profissão</InputLabel>
          <OutlinedInput
            id="nomeProfissaoConjuge"
            name="nomeProfissaoConjuge"
            placeholder="Digite a profissão do cônjuge"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="rendaConjuge">Renda</InputLabel>
          <OutlinedInput
            id="rendaConjuge"
            name="rendaConjuge"
            placeholder="Digite a renda do cônjuge"
            fullWidth
            formProps={formProps}
            InputProps={{
              inputComponent: CurrencyTextField
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="nomeEmpresaProfissaoConjuge">Nome Empresa</InputLabel>
          <OutlinedInput
            id="nomeEmpresaProfissaoConjuge"
            name="nomeEmpresaProfissaoConjuge"
            placeholder="Digite o nome da empresa cônjuge"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default Conjuge;
