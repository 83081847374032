import basicFlow, { genericErrorHandler } from './asyncHandler';
import { obterDadosRequest, obterDadosFailure, obterDadosSuccess } from '../reducers/whitelabel.reducer';
import { unauthenticatedRequest } from '../utils/api';
import { put, select, takeLatest } from 'redux-saga/effects';
import { getPathname } from '../selectors/routes.selectors';

const paletaCoresApi = (values) => {
  return unauthenticatedRequest({
    url: `/configuracao-parceiro/whitelist/paleta-cores`,
    body: {
      urlParceiro: values.url,
      idParceiro: values.parceiro
    },
    method: 'POST',
  });
};

function* geracaoPaletaCores() {
  yield put(obterDadosRequest());
}

function* watchPaletaCores() {
  yield takeLatest('START_PALETA_CORES', geracaoPaletaCores);
}

const paletaCoresFlow = basicFlow({
  actionGenerator: obterDadosRequest,
  actionFailure: obterDadosFailure,
  actionSuccess: obterDadosSuccess,
  transform: function* () {
    let parceiro = process.env.REACT_APP_PARTNER_ID;
    if (!parceiro)
      parceiro = yield select((state) => state.usuario?.idParceiro);
    if (parceiro) {
      return {parceiro: parceiro, url: null}
    }
    const url = yield select(getPathname);
    return {parceiro: null, url: url};
  },
  api: paletaCoresApi,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  },
});


export const sagas = [
  paletaCoresFlow.watcher(),
  watchPaletaCores(),
];
