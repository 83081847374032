import { createTheme } from '@mui/material/styles';
import { presetPalettes } from '@ant-design/colors';
import ThemeOption from './theme';
import { useSelector } from 'react-redux';

const Palette = (mode) => {
  const colors = presetPalettes;
  const greyPrimary = [
    '#ffffff', '#fafafa', '#f5f5f5', '#f0f0f0', '#d9d9d9', '#bfbfbf',
    '#8c8c8c', '#595959', '#262626', '#141414', '#000000'
  ];
  const greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
  const greyConstant = ['#fafafb', '#e6ebf1'];
  colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant];

  // Obtém dados do Redux
  const { corPrimaria, corSecundaria, corHeader, corFundoLogin, corFonteHeader } =
    useSelector((state) => state.whitelabel.parceiro.paleta);
  const loading = useSelector((state) => state.whitelabel.parceiro.loading);

  // Se ainda estiver carregando, retorna null (ou um tema padrão temporário)
  if (loading) {
    return createTheme({
      palette: {
        mode,
        background: {
          default: '#fff' // Cor temporária enquanto carrega
        }
      }
    });
  }

  const paletteColor = ThemeOption(colors, corPrimaria, corSecundaria);

  return createTheme({
    palette: {
      mode,
      common: { black: '#000', white: '#fff' },
      ...paletteColor,
      customPurple: {
        main: '#210042',
        light: '#5d3673',
      },
      text: {
        primary: paletteColor.grey[700],
        secondary: paletteColor.grey[500],
        other: paletteColor.grey[600],
        disabled: paletteColor.grey[400]
      },
      action: { disabled: paletteColor.grey[300] },
      divider: paletteColor.grey[200],
      background: {
        paper: paletteColor.grey[0],
        default: paletteColor.grey.A50,
        gradient: 'radial-gradient(at top left, #360569 20%, #6900b2 100%)',
        auth: corFundoLogin ?? 'transparent'
      },
      header: {
        default: corHeader ?? '#360569',
        font: corFonteHeader ?? '#fff'
      }
    }
  });
};

export default Palette;
