import React from 'react';
import { Stack, Grid } from '@mui/material';
import OutlinedInput from 'components/form/OutlinedInput';
import InputLabel from 'components/form/InputLabel';
import Switch from '../../../components/form/Switch';

const DadosDoParceiro = ({ formProps, disabled, isWhiteLabel, setIsWhiteLabel, setIsDominioProprio, isDominioProprio }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Stack spacing={0}>
          <InputLabel htmlFor="fgUtilizaWhitelabel">
            Utilizará whitelabel?
          </InputLabel>
          <Switch
            formProps={formProps}
            fullWidth
            name="fgUtilizaWhitelabel"
            id="fgUtilizaWhitelabel"
            onChange={(e) => setIsWhiteLabel(e.target.checked)}
          />
        </Stack>
      </Grid>
      {isWhiteLabel && (<>
        <Grid item xs={12} md={6}>
          <Stack spacing={0}>
            <InputLabel htmlFor="fgUtilizaDominioProprio">
              Utilizará domínio próprio?
            </InputLabel>
            <Switch
              formProps={formProps}
              fullWidth
              name="fgUtilizaDominioProprio"
              id="fgUtilizaDominioProprio"
              onChange={(e) => setIsDominioProprio(e.target.checked)}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={12}>
          <Stack spacing={0}>
            <InputLabel htmlFor="urlWhitelabel">URL</InputLabel>
            <OutlinedInput
              disabled={disabled}
              id="urlWhitelabel"
              name="urlWhitelabel"
              placeholder="URL"
              fullWidth
              formProps={formProps}
              inputProps={{ maxLength: 50 }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={12} sx={{display: isDominioProprio ? "block" : "none"}}>
          <Stack spacing={0}>
            <InputLabel htmlFor="tituloNavegador">Título Mostrado no Navegador</InputLabel>
            <OutlinedInput
              disabled={disabled}
              id="tituloNavegador"
              name="tituloNavegador"
              placeholder="Título Mostrado no Navegador"
              fullWidth
              formProps={formProps}
              inputProps={{ maxLength: 50 }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={12} sx={{display: isDominioProprio ? "block" : "none"}}>
          <Stack spacing={0}>
            <InputLabel htmlFor="recaptchaKey">Recaptcha Key</InputLabel>
            <OutlinedInput
              disabled={disabled}
              id="recaptchaKey"
              name="recaptchaKey"
              placeholder="Recaptcha Key"
              fullWidth
              formProps={formProps}
              inputProps={{ maxLength: 50 }}
            />
          </Stack>
        </Grid>
      </>)}
    </Grid>
  );
};

export default DadosDoParceiro;


