import React from 'react';
import { Stack, Grid, IconButton, Tooltip } from '@mui/material';
import OutlinedInput from 'components/form/OutlinedInput';
import InputLabel from 'components/form/InputLabel';
import Switch from '../../../components/form/Switch';
import AnimateButton from '../../../components/@extended/AnimateButton';
import Button from '../../../components/Button';
import RegistrosDsn from './RegistrosDsn';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useSelector } from 'react-redux';

const Email = ({
                 formProps,
                 handleCriarIdentity,
                 handleConsultarIdentity,
                 identity,
                 setIsEmailProprio,
                 isEmailProprio
               }) => {

  const perfil = useSelector((state) => state.usuario.perfil);

  return (
    <>
      {perfil === "AD" && (
      <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <Stack spacing={0}>
            <InputLabel htmlFor="fgEmailProprio">
              E-mail próprio?
            </InputLabel>
            <Switch
              formProps={formProps}
              fullWidth
              name="fgEmailProprio"
              id="fgEmailProprio"
              onChange={(e) => setIsEmailProprio(e.target.checked)}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={5}>
          <Stack spacing={0}>
            <InputLabel htmlFor="dominioEmail">
              Domínio do E-mail
              <Tooltip title="Domínio do e-mail. Ex: homefin.com.br">
                <IconButton sx={{height: "25px", width: "25px", ml:2, pb: 2}} >
                  <InfoOutlinedIcon sx={{height: "20px"}} />
                </IconButton>
              </Tooltip>
            </InputLabel>
            <OutlinedInput
              disabled={!isEmailProprio}
              id="dominioEmail"
              name="dominioEmail"
              placeholder="Domínio do E-mail"
              fullWidth
              formProps={formProps}
              inputProps={{ maxLength: 50 }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={5}>
          <Stack spacing={0}>
            <InputLabel htmlFor="emailFrom">
              E-mail From
              <Tooltip title="Qual e-mail será o de saída. Ex: Homefin <crm@homefin.com.br>">
                <IconButton sx={{height: "25px", width: "25px", ml:2, pb: 2}} >
                  <InfoOutlinedIcon sx={{height: "20px"}} />
                </IconButton>
              </Tooltip>
            </InputLabel>
            <OutlinedInput
              disabled={!isEmailProprio}
              id="emailFrom"
              name="emailFrom"
              placeholder="E-mail From"
              fullWidth
              formProps={formProps}
              inputProps={{ maxLength: 100 }}
            />
          </Stack>
        </Grid>
      </Grid>
      <Grid
        container
        style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
        spacing={2}
      >
        <Grid item xs={12} md={4}>
          <AnimateButton>
            <Button
              disabled={!isEmailProprio}
              disableElevation
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              onClick={handleConsultarIdentity}
              isLoading={identity.loading}
            >
              CONSULTAR DNS
            </Button>
          </AnimateButton>
        </Grid>
        <Grid item xs={12} md={4}>
          <AnimateButton>
            <Button
              disabled={(!isEmailProprio) || identity.registros.length > 0}
              disableElevation
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              onClick={handleCriarIdentity}
              isLoading={identity.loading}
            >
              CRIAR IDENTITY
            </Button>
          </AnimateButton>
        </Grid>
      </Grid>
      </> )}
      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <RegistrosDsn />
        </Grid>
      </Grid>
    </>
  );
};

export default Email;


