// material-ui
import { DataGrid } from '../../../components/grid/DataGrid';

// project import
import React from 'react';
import {
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';

import { Box } from '@mui/material';

const TableCertificadoAws = (parceiro) => {

  const rows = parceiro.parceiro.dadosInfra

  const columns = [
    {
      field: 'dominio',
      headerName: '',
      flex: 0.3,
    },
    {
      field: 'tipo',
      headerName: 'Tipo',
      flex: 0.3,
    },
    {
      field: 'nome',
      headerName: 'Nome CNAME',
      flex: 1,
    },
    {
      field: 'valor',
      headerName: 'valor CNAME',
      flex: 1,
    },
  ];

  const CustomToolbar = ({ apiRef }) => {
    return (
      <>
        <GridToolbarContainer/>
      </>
    );
  };

  return (
    <Box sx={{ marginTop: '5px' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSizeOptions={[10]}
        toolbar={CustomToolbar}
        loading={parceiro.loading}
        pageSize={10}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10
            }
          },
          density: 'standard'
        }}
        noLineText="Nenhum registro encontrado"
      />
    </Box>
  );
};

export default TableCertificadoAws;
