// material-ui
import { DataGrid } from '../../../components/grid/DataGrid';

// project import
import { useSelector } from 'react-redux';
import React from 'react';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';

import { Box } from '@mui/material';

const RegistrosDsnPage = () => {

  const registros = useSelector((state) => state.parceiros.identity);

  const rows = registros.registros;

  const columns = [
    {
      field: 'tipo',
      headerName: 'Tipo',
      flex: 0.3
    },
    {
      field: 'nome',
      headerName: 'Nome',
      flex: 1
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      flex: 0.7
    },
    {
      field: 'valor',
      headerName: 'Valor',
      flex: 1
    }
  ];

  const CustomToolbar = ({ apiRef }) => {
    return (
      <>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{ includeHeaders: true, allColumns: true }}
          />
        </GridToolbarContainer>
      </>
    );
  };

  return (
    <Box sx={{ marginTop: '5px' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSizeOptions={[10]}
        getRowId={(row) => row.valor}
        toolbar={CustomToolbar}
        loading={registros.loading}
        pageSize={10}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10
            }
          },
          density: 'standard'
        }}
        noLineText="Nenhum registro encontrado"
      />
    </Box>
  );
};

export default RegistrosDsnPage;
