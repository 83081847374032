// material-ui
import { DataGrid } from '../../../components/grid/DataGrid';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

// project import
import React from 'react';
import {
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';

import { Box } from '@mui/material';

const TableDominioAws = (parceiro) => {

  const rows = [parceiro.parceiro]

  const columns = [
    {
      field: 's3Bucket',
      headerName: 'S3',
      flex: 1,
      valueGetter: (value) => {
        return !!value
      },
      renderCell: (params) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
            {params.value ? (
              <CheckIcon color="success" fontSize="small" />
            ) : (
              <ClearIcon color="error" fontSize="small" />
            )}
          </Box>
        )
      },
    },
    {
      field: 'arnCertificado',
      headerName: 'Certificado',
      flex: 1,
      valueGetter: (value) => {
        return !!value
      },
      renderCell: (params) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
            {params.value ? (
              <CheckIcon color="success" fontSize="small" />
            ) : (
              <ClearIcon color="error" fontSize="small" />
            )}
          </Box>
        )
      },
    },
    {
      field: 'idDistributionCloudfront',
      headerName: 'CloudFront',
      flex: 1,
      valueGetter: (value) => {
        return !!value
      },
      renderCell: (params) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
            {params.value ? (
              <CheckIcon color="success" fontSize="small" />
            ) : (
              <ClearIcon color="error" fontSize="small" />
            )}
          </Box>
        )
      },
    },
  ];

  const CustomToolbar = ({ apiRef }) => {
    return (
      <>
        <GridToolbarContainer>
        </GridToolbarContainer>
      </>
    );
  };

  return (
    <Box sx={{ marginTop: '5px', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSizeOptions={[10]}
        getRowId={(row) => row.idParceiro}
        toolbar={CustomToolbar}
        loading={parceiro.loading}
        pageSize={10}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10
            }
          },
          density: 'standard'
        }}
        noLineText="Nenhum registro encontrado"
      />
    </Box>
  );
};

export default TableDominioAws;
