// material-ui
import { Grid, Stack, Typography } from '@mui/material';

// project import
import AuthLogin from './auth-forms/AuthLogin';
import AuthWrapper from './AuthWrapper';
import { useSelector } from 'react-redux';

// ================================|| LOGIN ||================================ //



const Login = () => {
  const loading = useSelector((state) => state.whitelabel.parceiro.loading);

  return (
    <AuthWrapper sx={{ display: loading ? 'none' : 'block' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="center" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
            <Typography variant="h2">Seja Bem Vindo</Typography>
          </Stack>
          <Stack direction="row" justifyContent="center" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
            <Typography variant="h5">Faça o seu login</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <AuthLogin />
        </Grid>
      </Grid>
    </AuthWrapper>
  )
};

export default Login;
