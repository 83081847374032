import { authenticatedRequest, unauthenticatedRequest } from 'utils/api';
import basicFlow, { genericErrorHandler } from './asyncHandler';
import * as actions from 'reducers/parceiros.reducer';
import { routeWatcher } from './rotas.saga';
import { put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  actions as routeActions,
  types as routes
} from '../reducers/rotas.actions';

import { getPayload } from 'selectors/routes.selectors';
import { regionalRequest } from 'reducers/dominios.reducer';

const apiListarParceiros = () => {
  return authenticatedRequest({
    url: `/configuracao-parceiro/list`,
    method: 'get'
  });
};

const listarParceiros = basicFlow({
  actionGenerator: actions.listarParceirosRequest,
  actionFailure: actions.listarParceirosFailure,
  actionSuccess: actions.listarParceirosSuccess,
  api: apiListarParceiros,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiCriarParceiro = ({ value }) => {
  return authenticatedRequest({
    url: `/configuracao-parceiro/create`,
    method: 'POST',
    body: value
  });
};

const criarParceiros = basicFlow({
  actionGenerator: actions.criarParceiroRequest,
  actionFailure: actions.criarParceiroFailure,
  actionSuccess: actions.criarParceiroSuccess,
  api: apiCriarParceiro,
  preSuccess: function* ({ response, original }) {
    if (original.files.fileLogoLogin) {
      yield put(actions.uploadArquivoRequest({
          value: {
            file: original.files.fileLogoLogin,
            idParceiro: response.data.idParceiro,
            tipoReferencia: "LL"
          }
      }));
    }
    if (original.files.fileLogoHeader) {
      yield put(actions.uploadArquivoRequest({
        value: {
          file: original.files.fileLogoHeader,
          idParceiro: response.data.idParceiro,
          tipoReferencia: "LH"
        }
      }));
    }
    if (original.files.fileLogoHeaderRecolhido) {
      yield put(actions.uploadArquivoRequest({
        value: {
          file: original.files.fileLogoHeaderRecolhido,
          idParceiro: response.data.idParceiro,
          tipoReferencia: "LR"
        }
      }));
    }
    if (original.files.fileLogoEmail) {
      yield put(actions.uploadArquivoRequest({
        value: {
          file: original.files.fileLogoEmail,
          idParceiro: response.data.idParceiro,
          tipoReferencia: "LE"
        }
      }));
    }
    if (original.files.fileFavicon) {
      yield put(actions.uploadArquivoRequest({
        value: {
          file: original.files.fileFavicon,
          idParceiro: response.data.idParceiro,
          tipoReferencia: "FI"
        }
      }));
    }
  },
  postSuccess: function* ({ response }) {
    toast.success('Parceiro incluído com sucesso.');
    yield put(
      routeActions.redirectTo(routes.PARCEIRO, {
        idParceiro: response.data.idParceiro
      })
    );
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiEditarParceiro = ({ value }) => {
  return authenticatedRequest({
    url: `/configuracao-parceiro/update/${value.idParceiro}`,
    method: 'PUT',
    body: value
  });
};

const editarParceiros = basicFlow({
  actionGenerator: actions.editarParceiroRequest,
  actionFailure: actions.editarParceiroFailure,
  actionSuccess: actions.editarParceiroSuccess,
  api: apiEditarParceiro,
  preSuccess: function* ({ response, original }) {
    if (original.files.fileLogoLogin) {
      yield put(actions.uploadArquivoRequest({
        value: {
          file: original.files.fileLogoLogin,
          idParceiro: response.data.idParceiro,
          tipoReferencia: "LL"
        }
      }));
    }
    if (original.files.fileLogoHeader) {
      yield put(actions.uploadArquivoRequest({
        value: {
          file: original.files.fileLogoHeader,
          idParceiro: response.data.idParceiro,
          tipoReferencia: "LH"
        }
      }));
    }
    if (original.files.fileLogoHeaderRecolhido) {
      yield put(actions.uploadArquivoRequest({
        value: {
          file: original.files.fileLogoHeaderRecolhido,
          idParceiro: response.data.idParceiro,
          tipoReferencia: "LR"
        }
      }));
    }
    if (original.files.fileLogoEmail) {
      yield put(actions.uploadArquivoRequest({
        value: {
          file: original.files.fileLogoEmail,
          idParceiro: response.data.idParceiro,
          tipoReferencia: "LE"
        }
      }));
    }
    if (original.files.fileFavicon) {
      yield put(actions.uploadArquivoRequest({
        value: {
          file: original.files.fileFavicon,
          idParceiro: response.data.idParceiro,
          tipoReferencia: "FI"
        }
      }));
    }
  },
  postSuccess: function* (value) {
    yield toast.success('Parceiro editado com sucesso.');
    const { idParceiro } = yield select(getPayload);
    yield put(actions.mostrarParceiroRequest(idParceiro));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiMostrarParceiro = (id) => {
  return authenticatedRequest({
    url: `/configuracao-parceiro/show/${id}`,
    method: 'get'
  });
};

const mostrarParceiro = basicFlow({
  actionGenerator: actions.mostrarParceiroRequest,
  actionFailure: actions.mostrarParceiroFailure,
  actionSuccess: actions.mostrarParceiroSuccess,
  api: apiMostrarParceiro,
  postFailure: function* ({ error }) {
    yield toast.error('Parceiro não encontrado.');
  },
  postSuccess: function* (value) {
    if (value?.response?.data?.dominioEmail)
      yield put(actions.consultarIdentityRequest({domain: value.response.data.dominioEmail}));
  },
});

const apiCriarIdentity = (data) => {
  return authenticatedRequest({
    url: `/configuracao-parceiro/whitelist/criar-identity-ses`,
    method: 'post',
    body: data
  });
};

const criarIdentity = basicFlow({
  actionGenerator: actions.criarIdentityRequest,
  actionFailure: actions.criarIdentityFailure,
  actionSuccess: actions.criarIdentitySuccess,
  api: apiCriarIdentity,
  postFailure: function* ({ error }) {
    yield toast.error(error);
  }
});

const apiConsultarIdentity = (data) => {
  return authenticatedRequest({
    url: `/configuracao-parceiro/whitelist/listar-dns`,
    method: 'post',
    body: data
  });
};

const consultarIdentity = basicFlow({
  actionGenerator: actions.consultarIdentityRequest,
  actionFailure: actions.consultarIdentityFailure,
  actionSuccess: actions.consultarIdentitySuccess,
  api: apiConsultarIdentity,
  postFailure: function* ({ error }) {
    yield toast.error(error?.response?.data);
  }
});

const apiExcluirParceiro = ({ value }) => {
  return authenticatedRequest({
    url: `/configuracao-parceiro/delete/${value.idParceiro}`,
    method: 'delete'
  });
};

const excluirParceiro = basicFlow({
  actionGenerator: actions.excluirParceiroRequest,
  actionFailure: actions.excluirParceiroFailure,
  actionSuccess: actions.excluirParceiroSuccess,
  api: apiExcluirParceiro,
  postSuccess: function* () {
    yield toast.success('Parceiro excluído com sucesso.');
    yield put(actions.listarParceirosRequest());
  },
  postFailure: function* ({ error }) {
    yield toast.error('Exclusão não permitida (registros relacionados).');
  }
});

const apiListarUsuariosParceiros = (idParceiro) => {
  return authenticatedRequest({
    url: `/configuracao-parceiro/${idParceiro}/usuarios`,
    method: 'get'
  });
};

const listarUsuariosParceiros = basicFlow({
  actionGenerator: actions.listarUsuariosParceirosRequest,
  actionFailure: actions.listarUsuariosParceirosFailure,
  actionSuccess: actions.listarUsuariosParceirosSuccess,
  api: apiListarUsuariosParceiros,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiListarArquivos = (value) => {
  return authenticatedRequest({
    url: `/configuracao-parceiro/arquivos/${value}`,
    method: 'get'
  });
};

const listarArquivos = basicFlow({
  actionGenerator: actions.listarArquivosRequest,
  actionFailure: actions.listarArquivosFailure,
  actionSuccess: actions.listarArquivosSuccess,
  api: apiListarArquivos,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiUploadArquivo = ({ value }) => {
  const formData = new FormData();
  formData.append('idParceiro', value.idParceiro);
  formData.append('arquivo', value.file, value.file.name);
  formData.append('tipoReferencia', value.tipoReferencia ?? 'PC');

  return authenticatedRequest({
    url: `/configuracao-parceiro/upload`,
    method: 'post',
    body: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

const uploadArquivo = basicFlow({
  actionGenerator: actions.uploadArquivoRequest,
  actionFailure: actions.uploadArquivoFailure,
  actionSuccess: actions.uploadArquivoSuccess,
  api: apiUploadArquivo,
  postSuccess: function* ({ response, original }) {
    yield toast.success('Arquivo enviado com sucesso.');
    const { idParceiro } = yield select(getPayload);
    if (!idParceiro) return;
    yield put(actions.listarArquivosRequest(idParceiro));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiDeletarArquivo = (value) => {
  return authenticatedRequest({
    url: `/configuracao-parceiro/arquivos/${value}`,
    method: 'delete'
  });
};

const deletarArquivo = basicFlow({
  actionGenerator: actions.deletarArquivoRequest,
  actionFailure: actions.deletarArquivoFailure,
  actionSuccess: actions.deletarArquivoSuccess,
  api: apiDeletarArquivo,
  postSuccess: function* ({ response, original }) {
    yield toast.success('Arquivo deletado com sucesso.');
    const { idParceiro } = yield select(getPayload);
    if (!idParceiro) return;
    yield put(actions.listarArquivosRequest(idParceiro));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiListarFups = (values) => {
  return authenticatedRequest({
    url: `/configuracao-parceiro/fup/list?idParceiro=${values}`,
    method: 'get'
  });
};

const listarFups = basicFlow({
  actionGenerator: actions.listarFupsRequest,
  actionFailure: actions.listarFupsFailure,
  actionSuccess: actions.listarFupsSuccess,
  api: apiListarFups,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiCriarFup = ({ value }) => {
  return authenticatedRequest({
    url: `/configuracao-parceiro/fup/create`,
    method: 'POST',
    body: value
  });
};

const criarFups = basicFlow({
  actionGenerator: actions.criarFupRequest,
  actionFailure: actions.criarFupFailure,
  actionSuccess: actions.criarFupSuccess,
  api: apiCriarFup,
  postSuccess: function* (value) {
    toast.success('Comentário incluído com sucesso.');
    const { idParceiro } = yield select(getPayload);
    yield put(actions.listarFupsRequest(idParceiro));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

function* mostrarParceiroRouteWatcher() {
  yield routeWatcher(routes.PARCEIRO, function* () {
    const { idParceiro } = yield select(getPayload);
    if (idParceiro === 'novo') {
      yield put(regionalRequest());
      yield put(actions.desativarModoEdicao());
      yield put(actions.limparParceiro());
      return;
    }
    if (!idParceiro) return;
    yield put(regionalRequest());
    yield put(actions.mostrarParceiroRequest(idParceiro));
    yield put(actions.listarArquivosRequest(idParceiro));
    yield put(actions.ativarModoEdicao());
  });
}

function* listarParceirosRouteWatcher() {
  yield routeWatcher(routes.PARCEIROS, function* () {
    yield put(actions.listarParceirosRequest());
  });
}

const buscarCep = basicFlow({
  actionGenerator: actions.buscarCepRequest,
  actionFailure: actions.buscarCepFailure,
  actionSuccess: actions.buscarCepSuccess,
  api: ({ value }) => {
    return unauthenticatedRequest({
      url: `https://viacep.com.br/ws/${value}/json/`,
      method: 'get',
      isCep: true
    });
  },
  postSuccess: function* ({ response, original }) {
    if (!!original.successCallback) {
      yield original.successCallback(response, original.value);
    }
  },
  postFailure: function* (props) {
    if (!!props.original.failureCallback) {
      yield props.original.failureCallback(props);
    }
  }
});

function* parceiroArquivosRouteWatcher() {
  yield routeWatcher(routes.PARCEIRO_DOCUMENTO, function* () {
    const { idParceiro } = yield select(getPayload);
    if (!idParceiro) return;
    yield put(actions.mostrarParceiroRequest(idParceiro));
    yield put(actions.listarArquivosRequest(idParceiro));
  });
}

function* parceiroFupsRouteWatcher() {
  yield routeWatcher(routes.PARCEIRO_FUP, function* () {
    const { idParceiro } = yield select(getPayload);
    if (!idParceiro) return;
    yield put(actions.mostrarParceiroRequest(idParceiro));
    yield put(actions.listarFupsRequest(idParceiro));
  });
}

export const sagas = [
  listarParceiros.watcher(),
  criarParceiros.watcher(),
  editarParceiros.watcher(),
  mostrarParceiro.watcher(),
  excluirParceiro.watcher(),
  listarParceirosRouteWatcher(),
  mostrarParceiroRouteWatcher(),
  parceiroArquivosRouteWatcher(),
  parceiroFupsRouteWatcher(),
  listarArquivos.watcher(),
  listarUsuariosParceiros.watcher(),
  buscarCep.watcher(),
  uploadArquivo.watcher(),
  deletarArquivo.watcher(),
  listarFups.watcher(),
  criarFups.watcher(),
  criarIdentity.watcher(),
  consultarIdentity.watcher(),
];
